<template>
  <section class="policy-holder">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <!--      personal-->
      <el-row
        v-if="clientType===1"
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col
          :same="true"
        >
          <div class="form-item">
            <span>Title</span>
            <div class="car-type-radio">
              <el-form-item
                ref="policy_holder_title"
                :prop="notValid?'':'policy_holder_title'">
                <v-radio-group
                  v-model="formData.policy_holder_title"
                  :list="titleOption"
                  border
                  :disabled="checkDisabled('policy_holder_title')"
                  @change="handlerEmit"/>
              </el-form-item>
            </div>
          </div>
        </v-row-col>
        <!--        <v-row-col>-->
        <v-row-col
        >
          <div class="form-item">
            <span>Last Name</span>
            <el-form-item
              ref="policy_holder_last_name"
              :prop="notValid?'':'policy_holder_last_name'">
              <v-input
                v-model="formData.policy_holder_last_name"
                is-upper
                :disabled="checkDisabled('policy_holder_last_name')"
                @change="handlerEmit"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item">
            <span>First Name</span>
            <el-form-item
              ref="policy_holder_first_name"
              :show-message="false"
              :prop="notValid?'':'policy_holder_first_name'">
              <v-input
                v-model="formData.policy_holder_first_name"
                is-upper
                :disabled="checkDisabled('policy_holder_first_name')"
                @change="handlerEmit"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          v-if="showMotor||showTravel||showDomestic"
        >
          <div class="form-item">
            <span class="flex flex-sb pd-0">
              <span>Date of Birth</span>
              <span
                v-if="form.effective_date&&formData.policy_holder_birth"
                class="color-theme color-theme__important fw-400">{{effective_age(form.effective_date,formData.policy_holder_birth)}} yrs old</span>
            </span>
            <el-form-item
              ref="policy_holder_birth"
              :prop="notValid?'':'policy_holder_birth'">
              <v-date-picker
                v-model="formData.policy_holder_birth"
                :hasDeault="true"
                :disabled="checkDisabled('policy_holder_birth')"
                @change="handlerEmit"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item ">
            <span>Mobile Number</span>
            <el-form-item :prop="notValid?'':'policy_holder_mobile'">
              <v-input
                v-model="formData.policy_holder_mobile"
                prepend="+852"
                type="tel"
                :maxlength="8"
                :disabled="checkDisabled('policy_holder_mobile')"
                @change="handlerEmit"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div
            class="form-item birth-item">
            <el-form-item
              ref="policy_holder_id_type"
              prop="policy_holder_id_type"
              class="form-none-margin-bottom">
              <v-radio-group
                v-model="formData.policy_holder_id_type"
                :list="personalIdTypOptions"
                @change="handleCheckBlack"/>
            </el-form-item>
            <el-form-item
              ref="policy_holder_id_no"
              prop="policy_holder_id_no">
              <v-input
                v-model="formData.policy_holder_id_no"
                class="mg-t-5"
                is-upper
                @blur="handleCheckBlack"
                @change="handlerEmit"/>
            </el-form-item>
            <!--              <el-form-item-->
            <!--                v-else-->
            <!--              >-->
            <!--                <v-input-->
            <!--                  v-model="formData.pp_no"-->
            <!--                  class="mg-t-5"-->
            <!--                  @change="handlerEmit"/>-->
            <!--              </el-form-item>-->
          </div>
        </v-row-col>
        <v-row-col >
          <div class="form-item ">
            <span>Email <span class="tip-option">Optional</span></span>
            <el-form-item
              ref="policy_holder_email"
              prop="policy_holder_email">
              <v-input
                v-model="formData.policy_holder_email"
                is-lower
                @change="handlerEmit"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          v-if="showMotor||showTravel||showHome||showDomestic||showOther"
        >
          <div class="form-item ">
            <span>
              Occupation</span>
            <el-form-item
              ref="policy_holder_occ_id"
              prop="policy_holder_occ_id"
              :rules="rules.select">
              <!--                <v-input-select-->
              <!--                  v-if="!hasAddressTitle"-->
              <!--                  v-model="formData.policy_holder_occ"-->
              <!--                  :valueKey="'name'"-->
              <!--                  :isFillin="true"-->
              <!--                  :selectId.sync="formData.policy_holder_occ_id"-->
              <!--                  :list="globalOptions.occ"-->
              <!--                  @change="handlerEmit"-->
              <!--                />-->
              <v-select
                v-model="formData.policy_holder_occ_id"
                :list="globalOptions.occ"
                @change="handlerEmit"
              />
            </el-form-item>
            <el-form-item
              v-if="formData.policy_holder_occ_id==70"
              ref="policy_holder_occ"
              prop="policy_holder_occ"
              :rules="rules.input">
              <v-input
                v-model="formData.policy_holder_occ"
                :placeholder="'Please enter'"></v-input>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          v-if="formData.policy_holder_occ_id==17"
          same
          :same-number="24">
          <div class="form-item">
            <span>Will the vehicle be used for food delivery purpose?</span>
            <div class="car-type-radio">
              <el-form-item
                prop="is_delivery_purpose"
                :rules="rules.select">
                <v-radio-group
                  v-model="formData.is_delivery_purpose"
                  border
                  :list="yes2Options"
                />
              </el-form-item>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          v-if="showTravel"
          same>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Policyholder is one of the traveler?</span>
              <div class="car-type-radio">
                <el-form-item
                  ref="policy_holder_is_driver"
                  prop="policy_holder_is_driver">
                  <v-radio-group
                    v-model.number="formData.policy_holder_is_traveler"
                    :border="true"
                    :list="yesNo2Options"
                    @change="handlerEmit"/>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
      <!--      company-->
      <span
        v-if="clientType===2"
        class="mg-b-20 block litter-title mg-l-0">Company Information</span>
      <el-row
        v-if="clientType===2"
        class="row-bg"
        :gutter="20"
        justify="space-around">

        <!--          <v-row-col-->
        <!--            same-->
        <!--            :same-number="24">-->
        <!--            <span  class="holder-title">Policyholder</span>-->
        <!--          </v-row-col>-->

        <v-row-col
        >
          <div class="form-item ">
            <span>Company Name</span>
            <el-form-item
              ref="holder_company_name"
              :prop="notValid?'':'holder_company_name'">
              <v-input
                v-model="formData.holder_company_name"
                is-upper
                :disabled="checkDisabled('holder_company_name')"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item">
            <span>CI Number</span>
            <el-form-item
              ref="holder_company_id_no"
              prop="holder_company_id_no">
              <v-input
                v-model="formData.holder_company_id_no"
                is-upper
                :disabled="checkDisabled('holder_company_id_no')"
                @blur="handleCheckBlack"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item">
            <span>Company Phone Number</span>
            <el-form-item
              ref="holder_company_phone"
              :prop="notValid?'':'holder_company_phone'">
              <v-input
                v-model="formData.holder_company_phone"
                maxlength="8"
                :disabled="checkDisabled('holder_company_phone')"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item ">
            <span>Company Email <span class="tip-option">Optional</span></span>
            <el-form-item
              ref="holder_company_email"
              :prop="notValid?'':'holder_company_email'">
              <v-input
                v-model="formData.holder_company_email"
                is-lower
                :disabled="checkDisabled('holder_company_email')"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item">
            <span>Nature of Business</span>
            <el-form-item
              ref="holder_company_nature_id"
              :prop="notValid?'':'holder_company_nature'">
              <!--                <v-input-select-->
              <!--                  v-if="!hasAddressTitle"-->
              <!--                  v-model="formData.holder_company_nature"-->
              <!--                  :list="globalOptions.industry "-->
              <!--                  :selectId.sync="formData.holder_company_nature_id"-->
              <!--                  :valueKey="'name'"-->
              <!--                  :isFillin="true"-->
              <!--                  :disabled="checkDisabled('holder_company_nature_id')"/>-->
              <v-select
                v-model="formData.holder_company_nature_id"
                :list="globalOptions.industry"
                :disabled="checkDisabled('holder_company_nature_id')"/>
            </el-form-item>
            <el-form-item
              v-if="formData.holder_company_nature_id==48"
              ref="holder_company_nature"
              prop="holder_company_nature"
              :rules="rules.input">
              <v-input
                v-model="formData.holder_company_nature"
                :placeholder="'Please enter'"></v-input>
            </el-form-item>
          </div>
        </v-row-col>

        <v-row-col
          v-if="formData.holder_company_nature_id==14"
          same
          :same-number="24">
          <div class="form-item">
            <span>Will the vehicle be used for food delivery purpose?</span>
            <div class="car-type-radio">
              <el-form-item
                prop="is_delivery_purpose"
                :rules="rules.select">
                <v-radio-group
                  v-model="formData.is_delivery_purpose"
                  border
                  :list="yes2Options"
                />
              </el-form-item>
            </div>
          </div>
        </v-row-col>

      </el-row>

      <el-row
        v-if="clientType===2"
        :gutter="20"
        justify="space-around">
        <span class="mg-b-20 block litter-title">Contact Person</span>
        <v-row-col >
          <v-row-col
            same
          >
            <div class="form-item">
              <span>Contact Person Name</span>
              <el-form-item
                ref="holder_contact_name"
                prop="holder_contact_name">
                <v-input
                  v-model="formData.holder_contact_name"
                  is-upper
                  :disabled="checkDisabled('holder_contact_name')"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Mobile Number</span>
              <el-form-item :prop="notValid?'':'holder_contact_phone'">
                <v-input
                  v-model="formData.holder_contact_phone"
                  key-number
                  type="tel"
                  prepend="+852"
                  :maxlength="8"
                  :disabled="checkDisabled('holder_contact_phone')"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
      <v-title
        v-if="hasAddressTitle"
        title="Address"></v-title>
      <div class="address-content">
        <div class="card">
          <address-form
            ref="address-form"
            :formType="1"
            :title="hasAddressTitle?'':'Correspondence Address'"
            not-valid="not-valid"
            :formDataLock="formDataLock"
            :form="formData.correspondence_address"
            @sameAddress="handlerSameAddress"
            @getFormData="getSubFormData"/>
        </div>
      </div>
    </el-form>
  </section>
</template>
<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import AddressForm from '@/views/components/address-form'
import IDCheck from '@/utils/idcheck'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'PolicyHolder',
  components: {
    AddressForm,
    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    },
    hasAddressTitle:{
      type: Boolean,
      default: false
    },
    notValid:{
      type: Boolean,
      default: false
    },
  },
  data() {
    const checkIdNo = (rule, value, callback) => {
      const { policy_holder_id_no,policy_holder_id_type } = this.formData
      if( policy_holder_id_type == 1 && !IDCheck(value)&&value) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      if(!policy_holder_id_no){
        callback(new Error('請輸入'))
      }
      // else {
      //   if(!policy_holder_id_no && !value) {
      //     callback(new Error('請輸入正確的身份證號碼'))
      //   }
      // }
      callback()
    }
    const checkPolicyBirth = (rule, value, callback) => {
      const { policy_holder_birth } = this.formData
      if(!policy_holder_birth)
      {
        callback(new Error('Please enter 請輸入'))
      }
      let isAfter = this.check18Years(policy_holder_birth,new Date())
      if(!isAfter){
        callback(new Error('Must be 18 years of age or above\n' +
          '必須年滿18歲'))
      }
      callback()
    }

    const checkPPNo = (rule, value, callback) => {
      const { pp_no,policy_holder_id_type } = this.formData
      if(policy_holder_id_type===2&& !pp_no && !value) {
        callback(new Error('請輸入Passport'))
      }
      callback()
    }
    const checkName = (rule, value, callback) => {
      let {policy_holder_first_name,policy_holder_last_name} = this.formData||{}
      if(!policy_holder_first_name&&!policy_holder_last_name) {
        callback(new Error('Fill in at least one of the content 最少填寫一項內容'))
      }
      callback()
    }
    return {
      oldFormDataLock: -1,
      defautDate:'1980-01-01',
      formData: {
        /////policyholder
        policy_holder_title:'',
        policy_holder_name: '',//r
        policy_holder_first_name: '',//r
        policy_holder_last_name: '',//r
        policy_holder_mobile: '',//r
        policy_holder_email: '',
        policy_holder_id_no: '',
        policy_holder_id_type: 1,//1-hkid 2-passport 3-br
        policy_holder_occ_id: '',
        policy_holder_occ: '',
        policy_holder_birth: '',
        holder_company_name:'',
        holder_company_id_no:'',
        holder_company_email:'',
        holder_company_nature_id:null,
        holder_company_nature:'',
        holder_contact_name:'',
        holder_contact_email:'',
        holder_contact_phone:'',
        holder_contact_position:'',
        holder_company_phone:'',
        policy_holder_is_traveler:'',//traveler
        pp_no:'',
        correspondence_address: {
          room: '',
          floor: '',
          block: '',
          building: '',
          street: '',
          district: '',
          area: '',
          region: 1
        },
        is_delivery_purpose:'',
      },
      subForm: {
        'address-form': false
      },
      extraRules: {
        policy_holder_id_no: [
          { validator: checkIdNo, trigger: [ 'blur'] },
          {required:false }
        ],
        policy_holder_birth: [
          {  validator: checkPolicyBirth, trigger: ['change', 'blur']}
        ],
        policy_holder_last_name: [
          {  validator: checkName, trigger: ['change', 'blur']}
        ],
        policy_holder_first_name: [
          {  validator: checkName, trigger: ['change', 'blur']}
        ],
        // pp_no: [
        //   { validator: checkPPNo, trigger: ['blur'] }
        // ],

      }
    }
  },
  computed: {
    ...mapState('quotation', ['productID']),
    policy_holder_name(){
      let {policy_holder_first_name,policy_holder_last_name} = this.formData||{}
      return !policy_holder_first_name&&!policy_holder_last_name
    },
    type() {
      // return this.formData.cust_typ
      return 1
    },
    showMotor(){
      return this.productID==10
    },
    showContractor(){
      return this.productID==11
    },
    showTravel(){
      return this.productID==12
    },
    showDomestic(){
      return this.productID==16
    },
    showHome(){
      return this.productID==13
    },
    showOther(){
      return this.productID==18
    },
    localAllOptions() {
      const classId = this.formData.class_id
      if (classId === 14) {
        return this.allOptions
      } else {
        return this[`allOptions${classId}`]
      }
    },
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if (val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          //   // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
          let {vehicle_detail = {}} = val || {}
          let {is_delivery_purpose} = vehicle_detail||{}
          this.formData.is_delivery_purpose = is_delivery_purpose
          // 給 pp_no 賦值
          const {policy_holder_id_type, policy_holder_id_no,pp_no} = val
          // if (policy_holder_id_type === 2 ) {
          //   this.formData.pp_no = policy_holder_id_no||pp_no
          // }
          this.handleCheckBlack()
        }
        // 用于匹配是否更新了信息
        // this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      }
    },
    'formData.policy_holder_id_no': {
      immediate: true,
      handler(val) {
        if (this.formData.policy_holder_id_type === 1) {
          this.formData.policy_holder_id_no = val.toLocaleUpperCase()
        }
        // // this.$emit('change-driver', this.formData)
      },
    },
    policy_holder_name:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          if(this.$refs.form){
            if(val&&!this.isFirstName){
              this.$refs.form.validateField(['policy_holder_first_name','policy_holder_last_name'])

            }
            else  this.$refs.form.clearValidate(['policy_holder_first_name','policy_holder_last_name'])
            this.isFirstName = false
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('common', ['checkBlacklistStatus']),
    handleCheckBlack(){
      let {policy_holder_id_type,policy_holder_id_no,holder_company_id_no} = this.formData
      let params = {
        id_type :this.clientType==2?3:policy_holder_id_type,
        id_no:this.clientType==2?holder_company_id_no:policy_holder_id_no
      }
      this.checkBlacklistStatus(params)
      this.handlerEmit()
    },
    handlerEmit(){
      const {policy_holder_is_driver,policy_holder_is_traveler} = this.formData
      // if (this.formData.policy_holder_id_type === 2) {
      //   this.formData.policy_holder_id_no = this.formData.pp_no
      // }
      this.$emit('one-driver', false,this.formData)
      this.$emit('one-traveler', policy_holder_is_traveler == 1 ? true : false,this.formData)

    },
    checkDisabled(field) {
      const {disable_filed = []} = this.form
      return disable_filed.includes(field)
    },
    openConfirmDialog() {
      this.$refs['confirm-information'].changeDialog()
    },
    handlerConfirmChangeInfo(result) {
      this.$emit('confirm', result)
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerSameAddress(data){
      // this.subForm['address-form'] = true
      // this.formData = Object.assign(this.formData, data)
      // console.log('data',data)
      if(data)
        this.$emit('address',data)
      // this.sameAddress()
    },
    sameAddress(){
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$emit('address',this.formData)
    },
    submitForm(formName,is_draft) {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          let params = this.formData
          // if (params.policy_holder_id_type === 2) {
          //   params.policy_holder_id_no = params.pp_no
          // }
          if(this.clientType==2){
            params.policy_holder_id_type=3
          }
          this.$emit('getFormData', formName, params)
        }else {
          if(is_draft)
          {
            this.$ConfirmBox({
              title: '',
              message: 'Please enter the policyholder name and mobile number',
              confirmBtn:'OK',
              isCancel:false
            }).then(() => {
              // this.network().draftQuotation()
            })
          }else {
            this.scrollToValidDom(rules)
          }
        }
      })
      //
    },
  }
}
</script>
<style lang="scss" scoped>
.policy-holder {
  /deep/ .el-col{
    .el-col{
      padding-left: 0!important;
    }
  }
  .litter-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-left: 10px;
    display: block;
    &.mg-l-0{
      margin-left: 0;
    }
  }
  .address-content{
    margin-top: 20px;
  }
  .edit-holder-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;

    .card {
      padding-bottom: 40px;
    }
  }

  .title {
    display: block;
    padding-bottom: 15px;
    color: #616266;
    font-size: 20px;
    font-weight: 500;
  }

  .form-flex {
    display: flex;
    justify-content: space-between;

    & > .form-block {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .form-block {
    margin-bottom: 15px;

    & > span:first-child {
      display: block;
      padding-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .holder-title{
    margin-bottom: 16px;
    display: block;
  }
}

</style>
